import React, { Component } from "react";
// import { StyledBody, StyledModal, ChallengeInfo } from "./styles";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
import { ImageUrl } from "../../../utils/constants";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ChallengeInfo, StyledBody, StyledModal } from "../../ChallengeDashboardV2/TeamChallengeInvitePopup/styles";
import Spinner from "../../common/Spinner";

class DeleteSurveyPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teamChallenge: {title:'', start_date:'', image:'', id:''},
      showTeamChallengeInvite:false
    };
  }

  render() {
    const { showModal, onYesClick, onClose, isLoading } = this.props;
    return (
      <StyledModal
        show={showModal}
        onHide={onClose}
        dialogClassName={"info-popup"}
        backdropClassName="drop-back"
      >
        <Modal.Header style={{background:"#005C87"}}>
          <div>
            {" "}
            <span>Delete Survey</span>
          </div>
        </Modal.Header>
        <StyledBody>
          <ChallengeInfo>
            <div>
              <img src={ImageUrl + '/admin-survey/trash.svg'} style={{width:'253px', height:'160px',borderRadius:'6px'}}/>
            </div>
            <div className="que" style={{color:'#005C87',fontFamily: 'Rubik',fontSize: '18px',fontWeight: 400,lineHeight: '24px', opacity:'unset', marginTop:'26px'}}>Are you sure you want to Delete <span style={{fontFamily:"Rubik-Medium"}}>This Survey</span></div>
            <div className="que" style={{color:'rgba(0, 92, 135, 0.60)',fontFamily: 'Rubik',fontSize: '16px',fontWeight: 400,lineHeight: '20px', opacity:'unset'}}>{`Once a Survey is deleted, you can't recover it.`}</div>
            <div className="btns">
              <button className="btn1" style={{display:"flex",justifyContent:"center",alignItems:"center"}} disabled={isLoading} onClick={()=>onYesClick()}>{isLoading ? <Spinner color={"white"}/> : "Yes Delete!"}</button>
              <button className="btn2" onClick={()=>onClose()} style={{border: '2px solid rgba(0, 92, 135, 0.60)', color:'rgba(0, 92, 135, 0.60)'}}>Cancel</button>
            </div>
          </ChallengeInfo>
        </StyledBody>
      </StyledModal>
    );
  }
}
DeleteSurveyPopUp.defaultProps = {
  challenge: { name: "", duration: "", body: "" },
};
DeleteSurveyPopUp.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  challenge: PropTypes.object,
  behaviorChallengeDetailsById: PropTypes.object,
  fname: PropTypes.string,
  userCompany: PropTypes.string,
  teamChallengesAPI :PropTypes.func,
  teamChallenges:PropTypes.array,
  showTeamChallengeInvite:PropTypes.bool,
  onNoClick:PropTypes.func,
  onYesClick:PropTypes.func,
  isLoading:PropTypes.bool
};

const mapStateToProps = (state) => ({
  teamChallenges: state.challenges.teamChallenges,
})


export default connect(mapStateToProps, null)(withTranslation()(DeleteSurveyPopUp));

