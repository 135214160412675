import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
// import { ChallengeHeadermain } from "../../ChallengeDashboardV2/styles";
import { withRouter } from "react-router-dom";
import { GetSurveyLibrary } from "../../../redux/constants/apiConstants";
import { BASE_URL } from "../../../utils/config";
import { toast } from "react-toastify";
import Waiting from "../../Waiting";
import { HeaderWrapper, OverlayDashboard } from "../../HealthTabV2/styles";
import { MainEventsContainer } from "../../MainEvents/styles";
import { BuddyTab, BuddyTabContainer } from "../../PeopleHomeV2/styles";
import HoverButton from "../../common/HoverButton";
import {connect} from "react-redux";
import { getSurveyDetails } from "../../../redux/actions";
import { getPermissionStatus } from "../../../utils/methods";
import { closeIcon, customSurevyIcon } from "../../../utils/icons";
import SurveyCard from "./SurveyCard";
import CustomSurveyPopUp from "./CustomSurveyPopUp";


const CategorySurveys = (props) => {
  const { history, getSurveyDetails, userPermissions, match, role } = props;
  // const [CategorySurveys, setCategorySurveys] = useState([]);
  const[surveys,setSurveys]= useState([]);
  const[isCustomSurvey, setisCustomSurvey] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const componentMounted = useRef(true); // (3) compo


  const createCustomSurvey = getPermissionStatus(
    "Create custom survey",
    userPermissions
  );

  useEffect(() => {

    // const editData = this.props.history.location.state;
    getSurveys("active");

    return () => {
      // This code runs when component is unmounted
      componentMounted.current = false; // (4) set it to false when we leave the page
    };
  }, []);

  const getSurveys = async (status) => {
    setIsLoading(true);
    const AuthToken = localStorage.getItem("AUTH_TOKEN");

    let url = BASE_URL +  GetSurveyLibrary + '?status=' + status +  "&category=" + match.params.id  + '&created_by=' + 1 + "&questions=" + true ;
    await fetch(url, { method: "GET", headers: { AUTHTOKEN: AuthToken } })
      .then((res) => {
        res
          .json()
          .then((data) => {
            if (res.status === 200) {
              if (componentMounted.current) {
                setSurveys(data.survey)

                // setCategorySurveys(data.data);
                setIsLoading(false);
              }
            } else {
              toast.error(data.data.message);
            }
          })
          .catch((error) => {
            toast.error(error.message);
          });
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };


  const Cards = (data)=>
    (
      <div
        style={{
          display: data.length > 0 && "flex",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {data && data.length > 0 ? (
          data.map((survey, index) => (

            <SurveyCard role={role} survey={survey} key={index} getSurveyDetails={getSurveyDetails} userPermissions={userPermissions} type={"woliba"} getSurveys={getSurveys}/>
            
          ))
        ) : (
          <div>No data found</div>
        )}
        {/* <NoDataCard title={"There is not workout in the library you need to add workout?"} btnText={"Create Workout"} btnOnclick={() => props.history.push("/education/create-workout")}/> */}
      </div>
    
    )
  

  return (
    <React.Fragment>
      {isLoading ? (
        <Waiting />
      ) : (
        <React.Fragment>
          <OverlayDashboard>
            <MainEventsContainer marginTop="1">
              <HeaderWrapper padding={1} style={{ padding: "0px" }}>
                <BuddyTabContainer marginBottom="25px" style={{display:"flex",alignItems:"center"}}>

                  <HoverButton
                    onClick={() => history.goBack()}
                    svgPath={closeIcon()}
                    title={"Close"}
                  />{" "}

                  <BuddyTab
                    width
                    padding
                    active={false}
                    style={{lineHeight:"24px", fontFamily:"Rubik-Medium", marginLeft:"0px"}}
                  >
                    {surveys && surveys[0] && surveys[0].category && surveys[0].category.name && surveys[0].category.name || ""}
                  </BuddyTab>

                  {createCustomSurvey && (
                    <div
                      className="year-dropdown"
                      style={{ marginRight: "20px" }}
                    >
                      <HoverButton
                        title={"Create Custom Survey"}
                        onClick={() =>
                          history.push("/company/surveys/launch-custom")
                          // setisCustomSurvey(true)
                        }
                        svgPath={customSurevyIcon()}
                      />
                    </div>
                  )}
                </BuddyTabContainer>

                {Cards(surveys)} 
                {isCustomSurvey && <CustomSurveyPopUp showModal={isCustomSurvey} onClose={()=>setisCustomSurvey(false)}/>}

              </HeaderWrapper>
            </MainEventsContainer>
          </OverlayDashboard>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

CategorySurveys.propTypes = {
  history: PropTypes.object,
  surveys: PropTypes.array.isRequired,
  getSurveyDetails: PropTypes.func.isRequired,
  userPermissions: PropTypes.array,
  companyInfo:PropTypes.object,
  match:PropTypes.object,
  role:PropTypes.object,
};

const mapStateToProps = (state) => ({
  isLoading: state.surveys.isLoading,
  surveys: state.surveys.surveyLibraries,
  userPermissions: state.profileData.userPermissions,
});

const mapDispatchToProps = (dispatch) => ({
  getSurveyDetails: (surveyId, history, route) =>
    dispatch(getSurveyDetails(surveyId, history, route)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CategorySurveys)
);
