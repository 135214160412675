import React, { Component } from "react";
// import { StyledBody, StyledModal, ChallengeInfo } from "./styles";
import { Modal } from "react-bootstrap";
import PropTypes from "prop-types";
// import { ImageUrl } from "../../../utils/constants";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ChallengeInfo, StyledBody, StyledModal } from "../../ChallengeDashboardV2/TeamChallengeInvitePopup/styles";
import { withRouter } from "react-router-dom";
// import { ClosePopUp } from "../../AddRolesPopup/style";
import LazyImage from "../../common/LazyImage/LazyImage";
import { ImageUrl } from "../../../utils/constants";

class CustomSurveyPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { showModal, onClose, history,setActiveTab } = this.props;
    return (
      <StyledModal
        show={showModal}
        onHide={onClose}
        dialogClassName={"info-popup"}
        backdropClassName="drop-back"
        style={{top:"-20%"}}
      >
        <Modal.Header style={{background:"#005C87", }}>
          <div style={{display:"flex", justifyContent:"space-between", alignItems:"center",width:"100%"}}>
            {" "}
            <div><span>Create A Custom Survey</span></div>
            <div style={{cursor:"pointer"}}> <img src='/public/images/new_close_icon.png' style={{width:"18px", height:"18px"}}  onClick={onClose}/></div>
          </div>
        </Modal.Header>
        <StyledBody>
          <ChallengeInfo>
            <div style={{margin:"30px"}}>
              <span style={{color:"rgba(0, 92, 135, 1)", fontSize:"18px",lineHeight:"24px"}}>How you want to launch Survey</span>
            </div>
            <div style={{display:"flex", justifyContent:"center"}}>
              <div style={{border:"2px solid rgba(0, 92, 135, 0.3)",borderRadius:"3px", width:"360px", height:"120px"}}>
                <div style={{display:"flex", cursor:"pointer", alignItems:"center", height:"100%"}} onClick={()=>history.push("/company/surveys/launch-custom-survey")}>
                  {/* <div style={{width:"102px",height:"102px", background:"rgba(241, 201, 119, 0.4)", margin:"5px"}}></div> */}
                  <LazyImage style={{width:"102px",height:"102px",margin:"5px"}} src={ImageUrl + "/admin-survey/from_scratch.png"}/>
                  <div style={{display:"flex",flexDirection:"column", justifyContent:"center", width:"199px",textAlign:"left", marginLeft:"5px"}}>
                    <div><span style={{fontSize:"18px",lineHeight:"24px",fontFamily:"Rubik-Medium",color:"rgba(0, 92, 135, 1)"}}>From Scratch</span></div>
                    <div><span style={{fontSize:"14px",lineHeight:"20px",fontFamily:"Rubik",color:"rgba(0, 92, 135, 0.6)"}}>Write your own questions with the style and format you like</span></div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{marginBottom:"20px"}}>or</div>
            <div style={{display:"flex", justifyContent:"center"}}>
              <div style={{border:"2px solid rgba(0, 92, 135, 0.3)",borderRadius:"3px", width:"360px", height:"120px"}}>
                <div style={{display:"flex", cursor:"pointer", alignItems:"center", height:"100%"}} onClick={()=>{history.push("/company/surveys/library");setActiveTab && setActiveTab(3);}}>
                  {/* <div style={{width:"102px",height:"102px", background:"rgba(118, 171, 120, 0.4)", margin:"5px"}}></div> */}
                  <LazyImage style={{width:"102px",height:"102px",margin:"5px"}}src={ImageUrl + "/admin-survey/from_template.png"}/>
                  <div style={{display:"flex",flexDirection:"column", justifyContent:"center", width:"212px",textAlign:"left", marginLeft:"5px"}}>
                    <div><span style={{fontSize:"18px",lineHeight:"24px",fontFamily:"Rubik-Medium",color:"rgba(0, 92, 135, 1)"}}>From a template</span></div>
                    <div><span style={{fontSize:"14px",lineHeight:"20px",fontFamily:"Rubik",color:"rgba(0, 92, 135, 0.6)"}}>Save time with pre-made questions crafted by our experts</span></div>
                  </div>
                </div>
              </div>
            </div>
          </ChallengeInfo>
        </StyledBody>
      </StyledModal>
    );
  }
}
CustomSurveyPopUp.defaultProps = {
  challenge: { name: "", duration: "", body: "" },
};
CustomSurveyPopUp.propTypes = {
  showModal: PropTypes.bool,
  onClose: PropTypes.func,
  history: PropTypes.object,
  setActiveTab: PropTypes.func
};



export default withRouter(connect(null, null)(withTranslation()(CustomSurveyPopUp)));

